<template>
  <div class="service-form-part">
    <DormerMaterialRadioField
      ref="dormerMaterial"
      :choices="choices"
      @result="setService($event)"
      v-on="$listeners"
    />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import { dormerDefaultNL } from 'chimera/dormer/service'
import DormerMaterialRadioField from 'chimera/all/components/form/fields/dormerMaterial/DormerMaterialRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'DormerMaterialRadioFormPart',

  components: {
    DormerMaterialRadioField,
    ServiceField,
  },

  /**
   * @returns {{choices: Selectable[]}}
   */
  data() {
    return {
      choices: [
        new Selectable(
          'Kunststof dakkapel',
          'Kunststof dakkapel',
          'Kunststof dakkapel',
        ),
        new Selectable(
          'Houtlook dakkapel',
          'Houtlook dakkapel',
          'Houtlook dakkapel',
        ),
        new Selectable(
          'Dakkapel van hout',
          'Dakkapel van hout',
          'Dakkapel van hout',
        ),
        new Selectable('Weet ik niet', 'Weet ik niet', 'Weet ik niet'),
      ],
    }
  },

  methods: {
    /**
     * @param {object}  value
     */
    setService(value) {
      this.$refs.service.setValue(dormerDefaultNL.id)
    },
  },
}
</script>
