<template>
  <FormModal title="Wat voor dakkapel zoek je?">
    <template #body>
      <DormerMaterialFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import DormerMaterialFormStep from '~/components/form/steps/dormerMaterial/DormerMaterialFormStep'

export default {
  components: {
    DormerMaterialFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat voor dakkapel zoek je?',
      headDescription: 'Geef aan naar wat voor dakkapel je op zoek bent.',
      path: '/offertes-aanvragen/type-dakkapel',
      progressValue: 15,
      checkoutStep: 1,
    }
  },
}
</script>
