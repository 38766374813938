<template>
  <FormModal title="Wat is de gewenste breedte van de dakkapel?" show-back-btn>
    <template #body>
      <DormerWidthFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import DormerWidthFormStep from '~/components/form/steps/dormerWidth/DormerWidthFormStep'

export default {
  components: {
    DormerWidthFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat is de gewenste breedte van de dakkapel?',
      headDescription: 'Geef aan wat de gewenste breedte van de dakkapel is.',
      path: '/offertes-aanvragen/gewenste-breedte-dakkapel',
      progressValue: 30,
      checkoutStep: 2,
    }
  },
}
</script>
