<template>
  <RoofPitchRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import RoofPitchRadioField from 'chimera/all/components/form/fields/roofPitch/RoofPitchRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'RoofPitchRadioFormPart',

  components: {
    RoofPitchRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          '40-45 graden (standaard)',
          '40-45 graden (standaard)',
          '40-45 graden (standaard)',
        ),
        new Selectable('Anders', 'Anders', 'Anders'),
        new Selectable('Weet ik niet', 'Weet ik niet', 'Weet ik niet'),
      ],
    }
  },
}
</script>
