<template>
  <DormerWidthRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import DormerWidthRadioField from 'chimera/all/components/form/fields/dormerWidth/DormerWidthRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'DormerWidthRadioFormPart',

  components: {
    DormerWidthRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          'Tussen 1 en 2 meter',
          'Tussen 1 en 2 meter',
          'Tussen 1 en 2 meter',
        ),
        new Selectable(
          'Tussen 2 en 3 meter',
          'Tussen 2 en 3 meter',
          'Tussen 2 en 3 meter',
        ),
        new Selectable(
          'Tussen 3 en 4 meter',
          'Tussen 3 en 4 meter',
          'Tussen 3 en 4 meter',
        ),
        new Selectable(
          'Tussen 4 en 5 meter',
          'Tussen 4 en 5 meter',
          'Tussen 4 en 5 meter',
        ),
        new Selectable(
          'Tussen 5 en 6 meter',
          'Tussen 5 en 6 meter',
          'Tussen 5 en 6 meter',
        ),
        new Selectable(
          'Groter dan 6 meter',
          'Groter dan 6 meter',
          'Groter dan 6 meter',
        ),
        new Selectable(
          'Anders, weet ik niet',
          'Anders, weet ik niet',
          'Anders, weet ik niet',
        ),
      ],
    }
  },
}
</script>
