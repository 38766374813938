<template>
  <FormModal title="Wat is de hellingshoek van je dak?" show-back-btn>
    <template #body>
      <RoofPitchFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import RoofPitchFormStep from '~/components/form/steps/roofPitch/RoofPitchFormStep'

export default {
  components: {
    RoofPitchFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat is de hellingshoek van je dak?',
      headDescription: 'Geef aan nwat de hellingshoek van je dak is.',
      path: '/offertes-aanvragen/hellingshoek-van-dak',
      progressValue: 45,
      checkoutStep: 3,
    }
  },
}
</script>
