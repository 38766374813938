<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">Vind dé specialist in jouw regio</h2>

        <span class="card__text">
          Vergelijk offertes voordat je beslist en bespaar tot 40%!
        </span>

        <TCard class="header__card">
          <div class="card__form">
            <PostalFormStep :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext">
          Vergelijk 6 dakkapel leveranciers in jouw regio
        </span>
      </div>
    </Header>
    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Dakkapellen offertes vergelijken',
      headDescription:
        'Interesse in een offerte voor een dakkapel? Vergelijk dakkapellen op Dakkapellenvergelijker.nl. Je ontvangt gratis en snel offertes van specialisten uit jouw regio.',
      path: '/',
      usps: [
        {
          title: 'Beantwoord een paar vragen',
          text: 'Je vertelt ons wat je zoekt, hierdoor kunnen wij de juiste specialist uit jouw regio aan jou koppelen.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Ontvang gratis offertes',
          text: 'Je ontvangt maximaal zes offertes van specialisten die aan je behoeften voldoen. Hiermee bespaar je tot 40%!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Kies de juiste specialist',
          text: 'Wij hebben onze specialisten al gecheckt, je hoeft alleen maar de offertes te vergelijken en te kiezen.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.page-index .header--white--text .header__card {
  @media (min-width: 541px) {
    @apply max-w-[484px];
  }
}
</style>
